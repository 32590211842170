.menuBar {
    border-bottom: 1px solid var(--color-white);
    display: grid;
    grid-template-columns: 1fr auto;
    width: 100%;
    align-items: center;
}

.menuList {
    display: flex;
    flex-wrap: wrap;
    gap: 36px;
    color: var(--color-white);
    list-style-type: none;
    padding: 0;
    margin: 0;
    font: var(--font-weight-normal) 20px / var(--line-height) var(--font-family-mono);
}

.menuItem {
    cursor: pointer;
    padding-bottom: 12px;
    opacity: 0.66;
    border-bottom: 4px solid transparent;
    transition: opacity 0.3s ease, border-color 0.3s ease;
}

.menuItem:hover {
    opacity: 1;
    border-color: var(--color-secondary-hover);
}

.menuItem.active {
    opacity: 1;
    border-color: var(--color-white);
}

.exitButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 6px 6px 12px 6px;
    background: none;
    border: none;
    cursor: pointer;
}

.exitIcon {
    width: 20px;
    height: 20px;
}

@media (max-width: 991px) {
    .menuList {
        width: 100%;
        justify-content: space-around;
    }

    .menuItem {
        padding-bottom: 8px;
        white-space: initial; /* Allows text wrapping on smaller screens */
    }
}
