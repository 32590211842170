/* Import necessary fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Poppins:wght@700&family=Roboto+Mono:wght@400;700&display=swap');

/* CSS Variables */
:root {
    --font-family-sans-serif: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    --font-family-mono: 'Roboto Mono', monospace;
    --font-family-poppins: 'Poppins', -apple-system, Helvetica, sans-serif;
    --font-size-xsmall: 11px;
    --font-size-small: 13px;
    --font-size-medium: 16px;
    --font-size-large: 20px;
    --font-size-xlarge: 24px;
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 700;
    --color-white: #fff;
    --color-black: #000;
    --color-gray: #c1c1c1;
    --color-background: #000;
    --color-primary: rgba(255, 255, 255, 0.85);
    --color-primary-hover: #fff;
    --color-secondary: rgba(255, 255, 255, 0.66);
    --color-secondary-hover: rgba(255, 255, 255, 0.8);
    --color-disabled: rgba(255, 255, 255, 0.33);
    --color-accent: #007bff;
    --transition-duration: 0.3s;
    --border-radius: 10px;
    --line-height: 1.4;
    --letter-spacing: 0.6px;
}

/* Global Styles */
body {
    margin: 0;
    font-family: var(--font-family-sans-serif), sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* React H5 Audio Player Customizations */
.rhap_time,
.rhap_volume-button {
    color: var(--color-white) !important;
}

.rhap_progress-bar-show-download {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.rhap_download-progress {
    opacity: 0 !important;
}

.rhap_progress-indicator {
    width: 15px !important;
    height: 15px !important;
    background: var(--color-white) !important;
    top: -6px !important;
}

.rhap_progress-filled {
    background-color: rgba(255, 255, 255, 0.75) !important;
}

.rhap_volume-indicator {
    background: var(--color-white) !important;
    opacity: 1 !important;
    height: 11px !important;
    width: 11px !important;
}

.rhap_volume-bar {
    background: rgba(255, 255, 255, 0.2) !important;
    height: 3px !important;
}

.rhap_volume-filled {
    background: rgba(255, 255, 255, 0.75) !important;
}

.rhap_progress-container {
    width: 620px !important;
}

.rhap_volume-controls {
    flex: unset !important;
    width: 250px !important;
}

.rhap_progress-bar {
    height: 3px !important;
}

.rhap_progress_bar_small {
    height: 3px !important;
    width: 60cqw !important;
}

@container outer_audio_controls_wrapper (max-width: 991px) {
    .rhap_progress-bar {
        height: 3px !important;
        width: 80cqw !important;
    }

    .rhap_progress-container {
        width: 70cqw !important;
    }
}

@container outer_audio_controls_wrapper (max-width: 767px) {
    .rhap_progress-bar {
        height: 3px !important;
        width: 80cqw !important;
    }

    .rhap_progress-container {
        width: 70cqw !important;
    }
}

@container outer_audio_controls_wrapper (max-width: 600px) {
    .rhap_progress-bar {
        height: 3px !important;
        width: 60cqw !important;
    }

    .rhap_progress-container {
        width: 60cqw !important;
    }
}

@container outer_audio_controls_wrapper (max-width: 449px) {
    .rhap_progress-bar {
        height: 3px !important;
        width: 50cqw !important;
    }

    .rhap_progress-container {
        width: 50cqw !important;
    }
}

@container outer_audio_controls_wrapper (max-width: 349px) {
    .rhap_progress-bar {
        height: 3px !important;
        width: 40cqw !important;
    }

    .rhap_progress-container {
        width: 40cqw !important;
    }
}
