/* Using global .form styles from app.css */

.scriptContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.scriptLabel {
    color: var(--color-white);
    letter-spacing: var(--letter-spacing);
    font: var(--font-weight-bold) var(--font-size-small) / var(--line-height)
        var(--font-family-sans-serif);
}

.textareaWrapper {
    text-align: center;
    padding: 45px 45px;
    word-wrap: break-word;
    white-space: pre-wrap;
    border-radius: var(--border-radius);
    background-color: #fff8ed;
    margin-top: 12px;
    width: 100%;
    height: 740px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

@media (max-width: 991px) {
    .textareaWrapper {
        text-align: center;
        padding: 10px 10px;
        word-wrap: break-word;
        white-space: pre-wrap;
        border-radius: var(--border-radius);
        background-color: #fff8ed;
        margin-top: 12px;
        width: 100%;
        height: 500px;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
}

.scriptTextarea {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    padding: 0 0.8em;
    resize: none;
    font: inherit;
    color: var(--color-black);
    text-align: center;
    outline: none;
    overflow: scroll;
    font-family: var(--font-family-sans-serif), sans-serif;
}

.scrollbar {
    border-radius: 11px;
    background-color: var(--color-gray);
    align-self: flex-start;
    position: absolute;
    z-index: 1;
    width: 8px;
    height: 268px;
    right: 13px;
    top: 15px;
}

.menuBar {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.menuList {
    display: flex;
    min-width: 240px;
    align-items: center;
    gap: 36px;
    white-space: nowrap;
    justify-content: flex-start;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menuItem {
    align-self: stretch;
    padding-bottom: 6px;
    cursor: pointer;
    color: var(--color-white);
    letter-spacing: var(--letter-spacing);
    font: var(--font-weight-bold) var(--font-size-small) / var(--line-height)
        var(--font-family-sans-serif);
}

.active {
    border-bottom: 1px solid var(--color-white);
}
