/* Unique styles specific to StarSignSelector.module.css */

.signs {
    display: flex;
    margin-top: 11px;
    width: 100%;
    align-items: flex-start;
    gap: 10px;
    font-size: 13px;
    color: var(--color-secondary);
    font-weight: var(--font-weight-normal);
    white-space: nowrap;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.sign {
    border-radius: 32px;
    background-color: var(--color-background);
    display: flex;
    min-height: 32px;
    align-items: center;
    gap: 8px;
    justify-content: center;
    padding: 0 12px;
    cursor: pointer;
    transition: background-color var(--transition-duration), border var(--transition-duration);
    box-shadow: none;
    border: 0;
    color: var(--color-secondary);
    position: relative;
}

.sign:hover::after {
    content: attr(title);
    position: absolute;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #3c4043;
    color: var(--color-white);
    font-family: var(--font-family-mono), monospace;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-normal);
    line-height: 150%;
    white-space: nowrap;
    pointer-events: none;
    z-index: 1000;
    bottom: calc(100% + 6px);
}

.sign:hover,
.sign.selected {
    background-color: var(--color-secondary-hover);
    color: rgba(0, 0, 0, 0.85);
}

.signIcon {
    width: 20px;
    height: 20px;
    background: none;
    border: none;
    transition: none;
}

.signName {
    font-family: var(--font-family-mono), monospace;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.redFlag {
    display: flex;
    align-items: center;
    margin-top: 11px;
    gap: 8px;
    color: red;
}

.redFlagIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.redFlagText {
    font-size: 13px;
    font-family: var(--font-family-mono), monospace;
    font-style: italic;
    margin: 0;
    color: var(--color-white);

    & .heavy {
        font-weight: var(--font-weight-bold);
    }
}

@media (max-width: 991px) {
    .signs {
        max-width: 100%;
        white-space: initial;
    }
}
