.createContent {
    color: var(--color-white);
}

.experimentInfo {
    color: var(--color-white);
    margin: 0;
    font: var(--font-weight-normal) var(--font-size-small) / 18px var(--font-family-mono);
}

.stepTitle {
    color: var(--color-white);
    margin-top: 48px;
    margin-bottom: 16px;
    font: var(--font-weight-bold) var(--font-size-large) / var(--line-height)
        var(--font-family-mono);
}

@media (max-width: 991px) {
    .experimentInfo,
    .stepTitle {
        max-width: 100%;
    }
}
