.experimentArea {
    display: flex;
    width: 100%;
    padding-bottom: 120px;
    justify-content: flex-start;
    flex-wrap: wrap;
}

@media (min-width: 1245px) {
    .leftSpacer,
    .rightSpacer {
        display: flex;
        width: 130px;
        height: 816px;
        flex: 1;
        flex-basis: 0;
    }
}

.content {
    align-self: flex-start;
    display: flex;
    min-width: 240px;
    flex-direction: column;
    justify-content: flex-start;
    width: 1180px;
    padding: 82px 0 64px;
}

.experiment {
    display: flex;
    width: 100%;
    gap: 40px 48px;
    justify-content: center;
    flex-wrap: wrap;
}

@media (max-width: 1245px) {
    .experimentArea {
        max-width: 100%;
        padding-bottom: 100px;
        padding-left: 2vw;
        padding-right: 2vw;
    }

    .content,
    .experiment {
        padding-top: 0;
        max-width: 100%;
    }
}

@media (max-width: 786px) {
    .experimentArea {
        max-width: 100%;
        padding-bottom: 100px;
        padding-left: 3vw;
        padding-right: 3vw;
    }
}
