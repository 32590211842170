.mediaGrid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 36px 16px;
}

@media (max-width: 991px) {
    .mediaGrid {
        max-width: 100%;
    }
}
