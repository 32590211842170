.labsMain {
    display: flex;
    flex-direction: column;
    justify-content: start;
    min-height: 100vh;
}

.header {
    border-bottom: 1px solid #fff;
}

.headerHome {
    text-decoration: none;
    display: flex;
    align-items: center;
    align-self: stretch;
}

@media (max-width: 991px) {
    .header {
        max-width: 100%;
    }
}

.logoContainer {
    background-color: #fff;
    display: flex;
    min-height: 80px;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    margin: auto 0;
}

.logoImage {
    width: 43px;
    height: 39.529px;
    flex-shrink: 0;
    fill: #051966;
}

.headerContent {
    display: flex;
    padding: 0 0 0 32px;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    font-family: var(--font-family-mono), monospace;
}

.headerContentPickford {
    color: var(--color-primary);
    font-size: var(--font-size-medium);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: calc(var(--font-size-large) * var(--line-height));
    letter-spacing: var(--letter-spacing);
}

.headerContentLabs {
    color: var(--color-primary);
    font-size: var(--font-size-medium);
    font-style: normal;
    font-weight: var(--font-weight-bold);
    line-height: calc(var(--font-size-large) * var(--line-height));
    letter-spacing: var(--letter-spacing);
}

@media (max-width: 991px) {
    .headerContent {
        max-width: 100%;
    }
}

.experimentArea {
    display: flex;
    width: 100%;
    padding-bottom: 120px;
    justify-content: start;
    flex-wrap: wrap;
    flex: 1;
}

@media (max-width: 991px) {
    .experimentArea {
        max-width: 100%;
        padding-bottom: 100px;
    }
}

@media (min-width: 1245px) {
    .sideColumn {
        display: flex;
        width: 130px;
        height: 681px;
        flex: 1;
        flex-basis: 0;
    }
}

.content {
    align-self: start;
    display: flex;
    min-width: 240px;
    flex-direction: column;
    justify-content: center;
    width: 1180px;
    padding: 48px 0 64px;
    gap: 53px;
}

@media (max-width: 991px) {
    .content {
        max-width: 100%;
    }
}

.learnMore {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 100%;
    color: rgba(255, 255, 255, 0.66);
    font: 400 15px/1.4 Roboto Mono, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
    .learnMore {
        max-width: 100%;
    }
}

.experiments {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 90px;
    justify-content: start;
}

@media (max-width: 991px) {
    .experiments {
        max-width: 100%;
        margin-top: 40px;
    }
}

.experiment {
    display: flex;
    min-height: 213px;
    width: 100%;
    align-items: flex-start;
    gap: 56px;
    justify-content: start;
    flex-wrap: wrap;
}

@media (max-width: 991px) {
    .experiment {
        max-width: 100%;
    }
}

.clickableCard {
    cursor: pointer;
}

/* New CSS rule to increase glyph opacity on hover */
.clickableCard:hover .glyph {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

/* New CSS rule to underline the title on hover */
.clickableCard:hover .experimentTitle {
    text-decoration: underline;
}

.glyph {
    border: 6px solid #fff;
    border-bottom-width: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    width: 160px;
    height: 160px;
    opacity: 0.85;
    transition: opacity 0.5s ease-in-out;
}

.glyphImage {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 160px;
}

.info {
    display: flex;
    min-width: 240px;
    flex-direction: column;
    justify-content: start;
    flex: 1 1 0;
    gap: 12px;
}

@media (max-width: 991px) {
    .info {
        max-width: 100%;
    }
}

.experimentTitle {
    color: #fff;
    margin-bottom: 0;
    font: 700 32px/1.4 Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
    .experimentTitle {
        max-width: 100%;
    }
}

.experimentDescription {
    color: #fff;
    margin-top: 0;
    margin-bottom: 0;
    font: 500 15px/1.4 Roboto Mono, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
    .experimentDescription {
        max-width: 100%;
    }
}

.experimentDetails {
    color: rgba(255, 255, 255, 0.66);
    margin-top: 0;
    margin-bottom: 0;
    font: 400 15px/21px Roboto Mono, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
    .experimentDetails {
        max-width: 100%;
    }
}

.partners {
    align-self: start;
    display: flex;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 24px;
    align-items: center;
    gap: 32px;
    justify-content: start;
    flex-wrap: wrap;
}

.partnerLogo {
    object-fit: contain;
    object-position: center;
    align-self: stretch;
    margin: auto 0;
}

.learnMoreLink {
    text-decoration: underline;
    color: #fff;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

@media (max-width: 1245px) {
    .content {
        max-width: 100%;
        padding-bottom: 100px;
        padding-left: 2vw;
        padding-right: 2vw;
    }
    .experiment {
        max-width: 100%;
    }
}

@media (max-width: 786px) {
    .content {
        max-width: 100%;
        padding-bottom: 100px;
        padding-left: 3vw;
        padding-right: 3vw;
    }
}
