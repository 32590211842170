.sidebar {
    display: flex;
    padding-top: 40px;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    width: 160px;
    gap: 32px;
}

.glyph {
    border: 6px solid #fff;
    border-bottom-width: 8px;
    display: flex;
    width: 160px;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    height: 160px;
}

.glyphImage {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 100%;
}

.info {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
}

.title {
    color: var(--color-white);
    margin: 0;
    font: var(--font-weight-bold) var(--font-size-xlarge) / var(--line-height)
        var(--font-family-poppins);
}

.description {
    color: var(--color-white);
    margin: 0;
    font: var(--font-weight-medium) var(--font-size-small) / 18px var(--font-family-mono);
}

.partners {
    align-self: flex-start;
    display: flex;
    padding-top: 24px;
    align-items: center;
    gap: 24px;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.partnerImage {
    aspect-ratio: 1.67;
    object-fit: contain;
    object-position: center;
    width: 40px;
    align-self: stretch;
    margin: auto 0;
}

.partnerImage:last-child {
    aspect-ratio: 1.88;
    width: 45px;
}
