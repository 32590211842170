/* Form Container */
.sharedStyles.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-background);
    padding: 20px;
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
    color: var(--color-white);
}

.playHeader {
    display: flex;
    flex-direction: row;
    margin-top: 3vh;
    justify-content: space-between;
    margin-bottom: 20px;
}

.playTitle {
    font-family: var(--font-family-mono), sans-serif;
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
    margin: 0;
}

.returnLink {
    font-family: var(--font-family-mono), sans-serif;
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
    color: var(--color-primary);
    margin: 0;
}

.creatorInfo {
    font-family: var(--font-family-mono), sans-serif;
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-normal);
    color: var(--color-secondary);
    margin-top: 5px;
}

.projectInfo {
    color: #fff;
    font-family: var(--font-family-mono), monospace;
    font-size: var(--font-size-small);
}

.cardPairing {
    font-weight: var(--font-weight-normal);
    margin: 6px 0 0;
}

.cardCreator {
    font-weight: var(--font-weight-normal);
    margin: 6px 0 0;
}

.cardStats {
    font-weight: var(--font-weight-medium);
    margin: 6px 0 0;
}

.statBold {
    font-weight: var(--font-weight-bold);
}

.statNormal {
    font-weight: var(--font-weight-normal);
}

/* Responsive Styling */
@media (max-width: 600px) {
    .playTitle {
        font-size: var(--font-size-large);
    }

    .creatorInfo {
        font-size: var(--font-size-small);
    }

    .sharedStyles.button {
        font-size: var(--font-size-small);
        padding: 8px 16px;
    }
}
