.formField {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: #fff;
    letter-spacing: 0.6px;
    justify-content: center;
    font: 700 12px/1.4 Inter, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
    .formField {
        max-width: 100%;
    }
}

.scriptEditContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
}

.scriptEditTextArea {
    height: 250px;
    padding: 10px 10px;
    border-radius: 10px;
    background: #000;
    border: none;
    resize: none;
    outline: none;
    font: inherit;
    color: #fff;
}

/*.formInput {*/
/*    border-radius: 10px;*/
/*    background-color: #000;*/
/*    color: white;*/
/*    display: flex;*/
/*    min-height: 36px;*/
/*    margin-top: 12px;*/
/*    width: 100%;*/
/*    font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;*/
/*    border: 0;*/
/*    padding-left: 10px;*/
/*}*/

.formInput:focus {
    outline: none;
}

@media (max-width: 991px) {
    .formInput {
        max-width: 100%;
    }
}

.buttonRow {
    display: flex;
    width: 100%;
    padding-top: 12px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}

@media (max-width: 991px) {
    .buttonRow {
        max-width: 100%;
        margin-top: 40px;
    }
}

.button {
    background-color: transparent;
    display: flex;
    min-height: 42px;
    align-items: center;
    gap: 8px;
    justify-content: center;
    padding: 0 20px;
    border: 1px solid rgba(255, 255, 255, 0.33);
    color: rgba(255, 255, 255, 0.33);
    cursor: pointer;
    transition: background-color 0.3s, border 0.3s;
}

.button:hover {
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: #000;
    background-color: #fff;
}

.buttonText {
    align-self: stretch;
    margin: auto 0;
    font: 400 13px/1.4 Inter, -apple-system, Roboto, Helvetica, sans-serif; /* Updated to use Inter font */
}

.buttonIcon {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    margin: auto 0;
    padding: 6px;
}

.buttonIconImage {
    aspect-ratio: 0.94;
    object-fit: contain;
    object-position: center;
    width: 15px;
    align-self: stretch;
    margin: auto 0;
}
