.findingsContent {
    display: flex;
    color: #fff;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;
}

.description {
    color: #fff;
    font: 500 13px/1.4 'Roboto Mono', -apple-system, Roboto, Helvetica, sans-serif;
    margin: 0;
}

.textareaWrapper {
    border-radius: 10px;
    background-color: #fff8ed;
    position: relative;
    display: flex;
    width: 100%;
    height: 587px;
    align-items: flex-start;
    overflow: hidden;
    justify-content: center;
    padding: 48px 10px;
}

.findingsTextarea {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    resize: none;
    font: inherit;
    color: #000;
    outline: none;
    font-family: var(--font-family-mono), monospace;

    /* Scrollbar styles for WebKit browsers (Chrome, Safari) */
    ::-webkit-scrollbar {
        width: 8px; /* Adjust the width of the scrollbar */
    }

    /* Remove scrollbar track background */
    ::-webkit-scrollbar-track {
        background: transparent; /* Transparent scrollbar background */
    }

    /* Customize the scrollbar handle */
    ::-webkit-scrollbar-thumb {
        background-color: #999; /* Color of the scrollbar handle */
        border-radius: 4px; /* Roundness of the scrollbar handle */
    }

    /* Scrollbar for Firefox */
    scrollbar-color: #999 transparent; /* Thumb color and track color */
    scrollbar-width: thin; /* Thin scrollbar */
}

.feedbackContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
}

.feedbackLabel {
    color: #fff;
    letter-spacing: 0.6px;
    font: var(--font-weight-bold) 12px/1.4 var(--font-family-mono), monospace;
}

.feedbackTextarea {
    height: 108px;
    padding: 10px 10px;
    border-radius: 10px;
    background: #000;
    border: none;
    resize: none;
    outline: none;
    font: inherit;
    color: #fff;
}

.feedbackTextInput {
    height: 30px;
    padding: 10px 10px;
    border-radius: 5px;
    background: #000;
    border: none;
    resize: none;
    outline: none;
    font: inherit;
    color: #fff;
}

.findingsContainer {
    background: #fff8ed;
    border-radius: 10px;
    padding: 48px 24px;
    height: 587px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 42px;
}

.findingCard {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.findingHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.findingTitle {
    color: #000;
    font-family: var(--font-family-mono), monospace;
    /*TODO: Is 14px intended. Maybe that should be a var if standard?*/
    font-size: 14px;
    font-style: normal;
    font-weight: var(--font-weight-bold);
    line-height: 140%;
    margin-bottom: 0;
}

.findingDate {
    color: #909090;
    font-family: var(--font-family-mono), monospace;
    /*TODO: Is 14px intended. Maybe that should be a var if standard?*/
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 0;
}

.findingSubtitle {
    color: #000;
    font-family: var(--font-family-mono), monospace;
    font-size: var(--font-size-medium);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: 140%;
    margin-bottom: 0;
}

.findingText {
    color: #000;
    font-family: var(--font-family-mono), monospace;
    font-size: var(--font-size-small);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: 175%;
    margin: 0;
}

.findingsContainer::-webkit-scrollbar {
    width: 8px;
}

.findingsContainer::-webkit-scrollbar-track {
    background: transparent;
}

.findingsContainer::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.findingsContainer::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.optionalFields {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 16px;
}

.inputField {
    margin: 16px 0 32px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
}

.separator {
    border: none;
    height: 1px;
    min-height: 1px;
    background: rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, 0.1);
}
