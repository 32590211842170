/* === FORM === */

.form {
    display: flex;
    margin-top: 48px;
    width: 100%;
    max-width: 932px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 48px;
}

.formField {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: #fff;
    letter-spacing: 0.6px;
    justify-content: center;
    font: 700 12px/1.4 Inter, -apple-system, Roboto, Helvetica, sans-serif;
}

.formInput {
    border-radius: 10px;
    background-color: #000;
    color: white;
    display: flex;
    min-height: 36px;
    margin-top: 12px;
    width: 100%;
    font-family: var(--font-family-mono), monospace;
    border: 0;
    padding-left: 10px;
}

.formInput:focus {
    outline: none;
}

/* === BUTTON === */

.buttonRow {
    display: flex;
    width: 100%;
    padding-top: 12px;
    align-items: flex-start;
    gap: 24px;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.buttonIcon {
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid transparent;
}

.buttonIconImage {
    width: 20px;
    height: 20px;
    transition: fill 0.3s ease;
}

.buttonText {
    font-family: var(--font-family-mono), monospace;
}

.button {
    display: flex;
    min-height: 42px;
    align-items: center;
    gap: 8px;
    justify-content: center;
    padding: 0 20px;
    border: none;
    font: 400 1em/1.4 Inter, -apple-system, Roboto, Helvetica, sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
    cursor: pointer;
}

.button[disabled] {
    position: relative;
}

.button[disabled]:hover::after {
    content: attr(title);
    position: absolute;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #3c4043;

    /* Typography */
    color: #fff;
    font-family: Roboto, -apple-system, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    white-space: nowrap;
    pointer-events: none;
    z-index: 1000;
    bottom: calc(100% + 17px);
    left: 50%;
    transform: translateX(-50%);
}

.button.inactive {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.33);
    border: 1px solid rgba(255, 255, 255, 0.33);
    cursor: not-allowed;
    stroke: rgba(255, 255, 255, 0.33);
}

.button.active-primary {
    background-color: rgba(255, 255, 255, 0.85);
    stroke: #000;
}

.button.active-primary:hover {
    background-color: #ffffff;
}

.button.active-secondary {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.85);
    border: 1px solid rgba(255, 255, 255, 0.85);
}

.button.active-secondary:hover {
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #ffffff;
}

/* === OPTION CLOUD === */

.optionCloud {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-family: Roboto Mono, sans-serif;
    line-height: 1.4;
    justify-content: center;
}

.optionCloudTitle {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.6px;
    margin-bottom: 0;
    font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif; /* Updated to use Inter font */
}

.optionCloudList {
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Enable horizontal scrolling */
    gap: 12px; /* Space between items */
    padding: 12px 0; /* Add some padding */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    scrollbar-width: none; /* Hide scrollbar on Firefox */
    -ms-overflow-style: none; /* Hide scrollbar on IE/Edge */
    flex-direction: row; /* Ensure horizontal layout */
    align-items: center; /* Center items vertically */
    margin-top: 12px;
}

/* Hide scrollbar on Chrome/Safari */
.optionCloudList::-webkit-scrollbar {
    display: none;
}

/* Style for each option button */
.option {
    flex: 0 0 auto; /* Don't grow or shrink, stay at base size */
    min-width: 280px; /* Set a fixed minimum width */
    max-width: 280px; /* Set a fixed maximum width */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Remove any conflicting styles */
@media (max-width: 767px) {
    .optionCloudList {
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: nowrap;
        margin-top: 12px;
        width: 100%;
        align-items: center;
    }
}

/* Desktop styles */
@media (min-width: 768px) {
    .optionCloudList {
        flex-wrap: wrap;
        justify-content: center;
        overflow-x: visible;
    }

    .option {
        min-width: auto;
        max-width: none;
    }
}

.optionTitle {
    font-family: var(--font-family-mono), monospace;
    font-size: var(--font-size-small);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: 140%;
}

.option {
    border-radius: 32px;
    background-color: #000;
    display: flex;
    min-height: 32px;
    align-items: center;
    gap: 8px;
    justify-content: center;
    padding: 0 12px;
    cursor: pointer;
    transition: background-color 0.3s, border 0.3s;
    box-shadow: none;
    border: 0;
    color: rgba(255, 255, 255, 0.66);
}

.option:hover {
    color: #fff;
}

.option.selected {
    background: rgba(255, 255, 255, 0.8);
    color: rgba(0, 0, 0, 0.85);
}

.option.selected:hover {
    background: #fff;
}

.playPauseButton {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s;
}

.selected:hover .playPauseButton {
    opacity: 1 !important;
}

.selected .optionTitle {
    opacity: 0.2;
    transition: opacity 0.2s;
}

.selected:not(:hover) .optionTitle {
    opacity: 1;
}

.optionCloud {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-family: var(--font-family-mono), monospace;
    line-height: var(--line-height);
    justify-content: center;
}

.optionCloudTitle {
    color: var(--color-white);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    letter-spacing: var(--letter-spacing);
    margin-bottom: 0;
    font-family: var(--font-family-mono), monospace;
}

.optionCloudList {
    display: flex;
    margin-top: 11px;
    width: 100%;
    align-items: flex-start;
    gap: 10px;
    font-size: 13px;
    color: var(--color-secondary);
    font-weight: var(--font-weight-normal);
    white-space: nowrap;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.option {
    border-radius: 32px;
    background-color: var(--color-background);
    display: flex;
    min-height: 32px;
    align-items: center;
    gap: 8px;
    justify-content: center;
    padding: 0 12px;
    cursor: pointer;
    transition: background-color var(--transition-duration), border var(--transition-duration);
    box-shadow: none;
    border: 0;
    color: var(--color-secondary);
    position: relative;
}

.option:hover {
    color: var(--color-white);
}

.option.selected {
    background: var(--color-secondary-hover);
    color: rgba(0, 0, 0, 0.85);
}

.option.selected:hover {
    background: var(--color-primary-hover);
}

/* === Mobile Styles === */
@media (max-width: 991px) {
    .buttonRow {
        max-width: 100%;
        margin-top: 40px;
    }

    .optionCloud {
        max-width: 100%;
        margin-top: 40px;
    }

    .optionCloudTitle {
        max-width: 100%;
    }

    .optionCloudList {
        max-width: 100%;
        white-space: initial;
    }
}
