.mediaPreview {
    display: flex;
    width: 100%;
    height: 48px;
    margin-top: -1px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #000000;

    container-name: outer_audio_controls_wrapper;
    container-type: inline-size;
}

.customAudioPlayer {
    background-color: #000000 !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #fff !important;
}

.playButton {
    color: #fff;
    font-size: 1.5rem;
    margin-right: 1rem;
    cursor: pointer;
}

.linesAndAudioWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.scrollableLinesContainer {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-height: 476px;
    overflow-y: auto;
    background-color: #000;
    padding: 0 54px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
}

.vsDisplay {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 2rem 0;
}

.vsSign {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.vsSignIcon {
    font-size: 3rem;
    color: #fff;
    height: 110px;
    width: 110px;
    stroke-width: 1;
}

.vsText {
    color: rgba(255, 255, 255, 0.5);
    font-family: var(--font-family-sans-serif), sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: var(--font-weight-medium);
    line-height: 140%;
}

.playerContainerNoLines {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 170px;
    background-color: #000;
    padding: 0 54px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
    overflow: hidden;

    container-name: player;
    container-type: size;

    .vsDisplay {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        padding: 2rem 0;
    }

    .vsSign {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
    }

    .vsSignIcon {
        font-size: 1.3rem;
        color: #fff;
        height: 32cqh;
        width: 32cqh;
        stroke-width: 1;
    }

    .vsText {
        color: rgba(255, 255, 255, 0.5);
        font-family: var(--font-family-sans-serif), sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: var(--font-weight-medium);
        line-height: 140%;
    }
}

.lineDisplay {
    padding: 27px 0;
    font-size: 0.9rem;
    text-align: center;
    font-weight: lighter;
    color: rgba(255, 255, 255, 0.3);
}

.currentLine {
    font-size: 1rem;
    color: #fff;
}

.lineCharacter {
    font-weight: bold;
}

.lineText {
    margin-left: 0.5rem;
}
