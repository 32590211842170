.mainContent {
    display: flex;
    min-width: 240px;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    width: 892px;
    gap: 36px;
}

.instruction {
    color: var(--color-white);
    margin: 0;
    font: var(--font-weight-medium) var(--font-size-small) / var(--line-height)
        var(--font-family-sans-serif);
}

.createLink {
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 991px) {
    .mainContent {
        max-width: 100%;
    }
}
