.mediaPreview {
    display: flex;
    width: 100%;
    height: 48px;
    margin-top: -1px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #000000;
}

.customAudioPlayer {
    background-color: #000000 !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #fff !important;
}

.playButton {
    color: #fff;
    font-size: 1.5rem;
    margin-right: 1rem;
    cursor: pointer;
}

.linesAndAudioWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.lineDisplay {
    padding: 27px 0;
    font-size: 0.9rem;
    text-align: center;
    font-weight: lighter;
    color: rgba(255, 255, 255, 0.3);
}

.currentLine {
    font-size: 1rem;
    color: #fff;
}

.lineCharacter {
    font-weight: bold;
}

.lineText {
    margin-left: 0.5rem;
}

.informationText {
    color: #fff;
    margin: 0;
    font: italic 400 13px/18px Roboto Mono, -apple-system, Roboto, Helvetica, sans-serif;
}

.vsDisplay {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 2rem 0;
}

.vsSign {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.vsSignIcon {
    font-size: 3rem;
    color: #fff;
    height: 96px;
    width: 96px;
    stroke-width: 1;
}

.vsText {
    color: rgba(255, 255, 255, 0.5);
    font-family: var(--font-family-sans-serif), sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.titleForm {
    display: grid;
    @media (min-width: 991px) {
        grid-template-columns: 1fr 1fr;
    }
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}

.titleGenerateButton {
    display: flex;
    height: 34px;
    align-items: center;
    gap: 8px;
    justify-content: center;
    padding: 0 20px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    font: 400 1em/1.4 Inter, -apple-system, Roboto, Helvetica, sans-serif;
    cursor: pointer;
    margin-top: 12px;
    border-radius: 10px;
    background-color: transparent;
    color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.titleGenerateButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: #fff;
    color: #fff;
}
