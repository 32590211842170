.instruction {
    color: #fff;
    margin-bottom: 36px;
    font: 500 13px/1.4 'Roboto Mono', -apple-system, Roboto, Helvetica, sans-serif;
}

.createLink {
    text-decoration: underline;
    cursor: pointer;
}
