/* Ensure the root, body, and app container take up the full height of the viewport */
html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure the App takes up the full height */
}

.content {
    flex: 1;
    transition: background-color 2000ms ease-in-out;
}

footer {
    background-color: #3d4253;
    color: var(--color-white);
    padding: 10px 0;
    text-align: center;
}

.footer-content {
    margin-bottom: 0;
    font-size: small;
}

footer .nav-link {
    color: var(--color-white);
    margin: 0 10px;
}

footer .nav-link:hover {
    color: var(--color-accent); /* Change icon color on hover */
}

/* === Global Form Styles === */
.form {
    display: flex;
    margin-top: 48px;
    width: 100%;
    max-width: 932px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 48px;
}

.formField {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: var(--color-white);
    letter-spacing: var(--letter-spacing);
    justify-content: center;
    font: var(--font-weight-bold) var(--font-size-small) / var(--line-height)
        var(--font-family-sans-serif);
}

.formInput {
    border-radius: var(--border-radius);
    background-color: var(--color-background);
    color: var(--color-white);
    display: flex;
    min-height: 36px;
    margin-top: 12px;
    width: 100%;
    font-family: var(--font-family-sans-serif), sans-serif;
    border: 0;
    padding-left: 10px;
}

.formInput:focus {
    outline: none;
}

/* === Global Button Styles === */
.buttonRow {
    display: flex;
    width: 100%;
    padding-top: 12px;
    align-items: flex-start;
    gap: 24px;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.buttonIcon {
    display: flex;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid transparent;
}

.buttonIconImage {
    width: 16px;
    height: 16px;
    transition: fill var(--transition-duration) ease;
}

.button {
    display: flex;
    min-height: 42px;
    align-items: center;
    gap: 8px;
    justify-content: center;
    padding: 0 20px;
    border: none;
    font: var(--font-weight-normal) 1em / var(--line-height) var(--font-family-sans-serif);
    transition: background-color var(--transition-duration) ease,
        color var(--transition-duration) ease, border var(--transition-duration) ease;
    cursor: pointer;
}

.button[disabled] {
    position: relative;
}

.button[disabled]:hover::after {
    content: attr(title);
    position: absolute;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #3c4043;
    color: var(--color-white);
    font-family: var(--font-family-sans-serif), sans-serif;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-normal);
    line-height: 150%;
    white-space: nowrap;
    pointer-events: none;
    z-index: 1000;
    bottom: calc(100% + 17px);
    left: 50%;
    transform: translateX(-50%);
}

.button.inactive {
    background-color: transparent;
    color: var(--color-disabled);
    border: 1px solid var(--color-disabled);
    cursor: not-allowed;
    stroke: var(--color-disabled);
}

.button.active-primary {
    background-color: var(--color-primary);
    stroke: var(--color-black);
}

.button.active-primary:hover {
    background-color: var(--color-primary-hover);
}

.button.active-secondary {
    background-color: transparent;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
}

.button.active-secondary:hover {
    background-color: transparent;
    color: var(--color-primary-hover);
    border: 1px solid var(--color-primary-hover);
}
