.mediaCard {
    display: flex;
    min-width: 240px;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
    gap: 12px;
}

.mediaPreview {
    background-color: #000;
    display: flex;
    min-height: 170px;
    max-width: 100%;
    width: 100%;
    align-items: center;
    overflow: hidden;
    justify-content: center;

    container-name: mediagrid_container;
    container-type: inline-size;
}

.previewIcon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 48px;
    align-self: stretch;
    margin: auto 0;
}

.cardContent {
    display: flex;
    max-width: 100%;
    width: 300px;
    flex-direction: column;
    color: #fff;
    justify-content: flex-start;
    font: 13px/1.4 var(--font-family-mono);
    text-decoration: none;
}

.cardContent:hover {
    text-decoration: underline;
}

.cardTitle {
    font: var(--font-weight-bold) var(--font-size-medium) / 1.4 var(--font-family-mono);
    margin: 0;
}

.cardMetadata {
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-xsmall);
    color: var(--color-primary);
    opacity: 66%;
    margin: 6px 0 0;
}

.cardStats {
    font-weight: 500;
    margin: 6px 0 0;
}

.statBold {
    font-weight: 700;
}

.statNormal {
    font-weight: 400;
}
